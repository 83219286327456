import i18next from "i18next";

import { apiUrlChatbot, apiUrlTicket } from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import LoadingHelper from "~helpers/LoadingHelper";
import Network from "~helpers/Network";

import * as actionTypes from "./actionTypes";

export const setScenarioFormState = (key, value) => ({
  type: actionTypes.SET_SCENARIO_FORM_STATE,
  payload: { key, value },
});

export const createOrUpdateScenarioManager = (isShiftPressed) => ({
  type: actionTypes.CREATE_OR_UPDATE_SCENARIO_MANAGER,
  payload: isShiftPressed,
});

export const resetScenarioManagerForm = () => ({
  type: actionTypes.RESET_FORM,
});

export const setScenarioLoading = (status) => ({
  type: actionTypes.SET_FORM_LOADING,
  payload: status,
});

export const setScenarioSaving = (status) => ({
  type: actionTypes.SET_FORM_SAVING,
  payload: status,
});

export const fetchScenarioForm = (id) => ({
  type: actionTypes.FETCH_SCENARIO_FORM,
  payload: { id },
});

export const setProcedureList = (payload) => ({
  type: actionTypes.SET_PROCEDURE_LIST,
  payload,
});

export const setSessionSamplePrediction = (payload) => ({
  type: actionTypes.SET_SESSION_SAMPLE_PREDICTION,
  payload,
});

export const setIntentSamplePrediction = (payload) => ({
  type: actionTypes.SET_INTENT_SAMPLE_PREDICTION,
  payload,
});

export const setIntentResponsePrediction = (payload) => ({
  type: actionTypes.SET_INTENT_RESPONSE_PREDICTION,
  payload,
});

export const setMediaList = (payload) => ({
  type: actionTypes.SET_MEDIA_LIST,
  payload,
});

export const setDatabaseList = (payload) => ({
  type: actionTypes.SET_DATABASE_LIST,
  payload,
});

export const setVariableList = (payload) => ({
  type: actionTypes.SET_VARIABLE_LIST,
  payload,
});

export const setInsight = (payload) => ({
  type: actionTypes.SET_INSIGHT,
  payload,
});
export const setInsightData = (payload) => ({
  type: actionTypes.SET_INSIGHT_DATA,
  payload,
});
// -----------  API ------------

export const createOrUpdateVariable = (projectId, chatbotId, data) => (_dispatch, _getState) => {
  let url = apiUrlChatbot.getVariable.format(projectId, chatbotId);
  if (data.id) {
    url += data.id + "/";
  }

  return Network.request(url, {
    method: data.id ? "PUT" : "POST",
    data,
    loading: true,
  });
};

export const getVariables = (projectId, chatbotId) => (_dispatch, _getState) => {
  const url = apiUrlChatbot.getVariable.format(projectId, chatbotId);

  return Network.request(url, {
    loading: true,
  });
};

export const deleteVariable = (projectId, chatbotId, id) => (_dispatch, _getState) => {
  const url = apiUrlChatbot.getVariable.format(projectId, chatbotId) + id + "/";

  return Network.request(url, {
    method: "DELETE",
    loading: true,
  });
};

export const getMedia = (projectId, chatbotId) => (_dispatch, _getState) => {
  const url = apiUrlChatbot.getMedia.format(projectId, chatbotId);

  return Network.request(url, {
    loading: true,
    params: { limit: 9999 },
  });
};

export const uploadMedia = (projectId, chatbotId, file, name) => (_dispatch, _getState) => {
  const url = apiUrlChatbot.getMedia.format(projectId, chatbotId);

  const form = new FormData();
  form.append("name", name || file.name);
  form.append("media_file", file);
  return Network.request(url, {
    method: "POST",
    data: form,
    loading: true,
    successMsg: true,
  });
};

export const updateMedia = (projectId, chatbotId, data) => (_dispatch, _getState) => {
  const url = apiUrlChatbot.getMedia.format(projectId, chatbotId) + data.id + "/";

  return Network.request(url, {
    method: "PUT",
    data,
    loading: true,
    successMsg: true,
  });
};

export const deleteMedia = (projectId, chatbotId, id) => (_dispatch, _getState) => {
  const url = apiUrlChatbot.getMedia.format(projectId, chatbotId) + id + "/";

  return Network.request(url, {
    method: "DELETE",
    loading: true,
    successMsg: true,
  });
};

export const uploadDatabase = (projectId, chatbotId, file, name) => (_dispatch, _getState) => {
  const url = apiUrlChatbot.getDatabase.format(projectId, chatbotId);

  const form = new FormData();
  form.append("name", name || file.name);
  form.append("data_file", file);
  return Network.request(url, {
    method: "POST",
    data: form,
    loading: true,
    successMsg: true,
  });
};

export const updateDatabase = (projectId, chatbotId, data) => (_dispatch, _getState) => {
  const url = apiUrlChatbot.getDatabase.format(projectId, chatbotId) + data.id + "/";

  return Network.request(url, {
    method: "PUT",
    data,
    loading: true,
    successMsg: true,
  });
};

export const deleteDatabase = (projectId, chatbotId, id) => (_dispatch, _getState) => {
  const url = apiUrlChatbot.getDatabase.format(projectId, chatbotId) + id + "/";

  return Network.request(url, {
    method: "DELETE",
    loading: true,
    successMsg: true,
  });
};

export const createOrUpdateProcedure =
  (projectId, botId, data, options = {}) =>
  (_dispatch, _getState) => {
    let url = apiUrlChatbot.getProcedure.format(projectId, botId);
    if (data.id) {
      url += data.id + "/";
    }

    return Network.request(url, {
      method: data.id ? "PUT" : "POST",
      data,
      loading: true,
      successMsg: true,
      ...options,
    });
  };
export const getProcedure = (projectId, botId, id) => (_dispatch, _getState) => {
  const url = `${apiUrlChatbot.getProcedure.format(projectId, botId)}${id}/`;

  return Network.request(url, {
    loading: true,
  });
};

export const getProcedureList = (projectId, botId, params) => (_dispatch, _getState) => {
  const url = apiUrlChatbot.getProcedure.format(projectId, botId);

  return Network.request(url, {
    loading: true,
    params: { limit: 9999, ...params },
    onSuccess: ({ results = [] }) => setProcedureList(results),
  });
};

export const getProcedurePlatformList = (projectId, params) => (_dispatch, _getState) => {
  const url = apiUrlChatbot.getProcedurePlatform.format(projectId);

  return Network.request(url, {
    loading: true,
    params: { limit: 9999, ...params },
  });
};

export const getProcedureVersion = (projectId, botId, procedureId, versionId, params) => (_dispatch, _getState) => {
  const url = apiUrlChatbot.getProcedureVersion.format(projectId, botId, procedureId, versionId);

  return Network.request(url, {
    loading: true,
    params,
  });
};

export const postDebugSession = (projectId, botId, data) => (_dispatch, _getState) => {
  const url = apiUrlChatbot.getDebugSession.format(projectId, botId);

  return Network.request(url, {
    method: "POST",
    data,
  });
};

export const deleteProcedure = (projectId, botId, id) => (_dispatch, _getState) => {
  const url = apiUrlChatbot.getProcedure.format(projectId, botId) + id + "/";

  return Network.request(url, {
    method: "DELETE",
    loading: true,
    successMsg: true,
  });
};
export const getIntent =
  (projectId, botId, intentId, options = {}) =>
  (_dispatch, _getState) => {
    let url = apiUrlChatbot.getIntents.format(projectId, botId);
    if (intentId) {
      url += intentId + "/";
    }

    return Network.request(url, {
      ...options,
    });
  };

export const getIntentList =
  (projectId, botId, options = {}) =>
  (_dispatch, _getState) => {
    const url = apiUrlChatbot.getIntents.format(projectId, botId);

    return Network.request(url, {
      ...options,
    });
  };

export const getIntentPrediction =
  (projectId, botId, text, options = {}) =>
  (_dispatch, _getState) => {
    const url = apiUrlChatbot.getIntentPrediction.format(projectId, botId);

    return Network.request(url, {
      method: "POST",
      data: { text },
      ...options,
    });
  };

export const getTicketCategories = (projectId) => (_dispatch, _getState) => {
  const url = apiUrlTicket.getCategory.format(projectId);

  return Network.request(url, { loading: true });
};

export const getIntentSamplePrediction = (projectId, botId, data) => (_dispatch, _getState) => {
  const { t } = i18next;
  const url = apiUrlChatbot.getIntentSample.format(projectId, botId);

  return Network.request(url, {
    method: "POST",
    data,
    onSuccess: ({ new_samples = [] }) => {
      const firstItem = new_samples?.[0];
      if (typeof firstItem !== "string") {
        AlertHelper.show(t("network.getIntentSamplePrediction.invalid"), "error");
        return setIntentSamplePrediction([]);
      } else {
        return setIntentSamplePrediction(new_samples);
      }
    },
    onFail: () => setIntentSamplePrediction([]),
  });
};

export const getIntentResponsePrediction = (projectId, botId, data) => (_dispatch, _getState) => {
  const url = apiUrlChatbot.getIntentResponse.format(projectId, botId);

  return Network.request(url, {
    method: "POST",
    data,
    onSuccess: ({ new_response = [] }) => setIntentResponsePrediction(new_response),
    onFail: () => setIntentResponsePrediction([]),
  });
};

export const getMediaList = (projectId, chatbotId) => (_dispatch, _getState) => {
  const url = apiUrlChatbot.getMedia.format(projectId, chatbotId);

  return Network.request(url, {
    loading: true,
    onSuccess: ({ results = [] }) => setMediaList(results),
    params: { limit: 9999 },
  });
};

export const getDatabaseList = (projectId, chatbotId) => (_dispatch, _getState) => {
  const url = apiUrlChatbot.getDatabase.format(projectId, chatbotId);

  return Network.request(url, {
    loading: true,
    onSuccess: ({ results = [] }) => setDatabaseList(results),
    params: { limit: 9999 },
  });
};

export const getVariableList = (projectId, chatbotId) => (_dispatch, _getState) => {
  const url = apiUrlChatbot.getVariable.format(projectId, chatbotId);

  return Network.request(url, {
    loading: true,
    onSuccess: ({ results = [] }) => setVariableList(results),
    params: { limit: 9999 },
  });
};

export const getInsight = (projectId, chatbotId, data) => async (_dispatch, _getState) => {
  try {
    const { t } = i18next;
    const url = apiUrlChatbot.getInsight.format(projectId, chatbotId);

    const timeoutMs = 1000 * 60 * 5; // 5 minutes

    LoadingHelper.open();
    const startDate = Date.now();
    const result = await Network.request(url, {
      method: "POST",
      // loading: true,
      data,
    });

    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (Date.now() - startDate > timeoutMs) {
        AlertHelper.show(t("network.getInsight.intentTimeout"), "error");
        break;
      }

      await new Promise((resolve) => setTimeout(resolve, 2000));
      const result2 = await Network.request(url + "/" + result.token, {
        loading: false,
        data,
      });

      if (result2.status === "DONE") {
        _dispatch(setInsight(result2.data));
        break;
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    LoadingHelper.close();
  }
};

//------------ RAG ------------

export const getRagFactGroup = (projectId, factGroupId) => (_dispatch, _getState) => {
  let url = apiUrlChatbot.getRagFactGroup.format(projectId, factGroupId);

  return Network.request(url, {
    loading: false,
  });
};

export const processRag = (projectId, factGroupId) => (_dispatch, _getState) => {
  let url = apiUrlChatbot.processRag.format(projectId, factGroupId);

  return Network.request(url, {
    method: "POST",
    loading: true,
  });
};
export const stopRag = (projectId, factGroupId) => (_dispatch, _getState) => {
  let url = apiUrlChatbot.stopRag.format(projectId, factGroupId);

  return Network.request(url, {
    method: "POST",
    loading: true,
  });
};

export const postMessageToRag = (projectId, factGroupId, text) => (_dispatch, _getState) => {
  let url = apiUrlChatbot.postRagMessage.format(projectId, factGroupId);

  return Network.request(url, {
    method: "POST",
    data: { text },
    loading: false,
  });
};
